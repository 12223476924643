<template>
  <div>
    <div>
      <v-dialog v-model="showLoginDialog" max-width="460" persistent>
        <Alert
          :alert-visible="showAlert"
          :alert-data="alertData"
          @closeModal="isAlertClosed"
        />
        <changeEmailForm
          v-if="showChangeEmailForm === true"
          @showAlert="onShowAlert"
          @closeModal="closeChangeEmailDialog"
        />
        <v-card v-else class="cart-alert rtl">
          <v-card-title>
            <h2>تسجيل دخول</h2>
          </v-card-title>
          <v-btn
            elevation="0"
            icon
            dark
            @click="showLoginDialog = false"
            class="close-dialog-icon"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-form ref="formRef" class="modal-form" lazy-validation>
            <v-text-field
              v-model="form.email"
              required
              class="input-email mb-1 font-weight-bold"
              placeholder="أدخل البريد الإلكتروني / إسم المستخدم"
              type="email"
              :rules="emailRules"
              @keydown.enter="login"
              validate-on-blur
              @blur="() => formRef.resetValidation()"
            />
            <v-text-field
              v-model="form.password"
              class="input-email font-weight-bold"
              required
              placeholder="أدخل كلمة المرور"
              type="password"
              :rules="passwordRules"
              @keydown.enter="login"
              validate-on-blur
              @blur="() => formRef.resetValidation()"
            />
            <div v-if="isError" class="v-text-field__details">
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message message-transition-enter-to text-error text-right">
                    {{ errorMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="forget-password">
              <span @click="forgetPassword">نسيت كلمة المرور</span>
              <v-checkbox
                v-model="form.remember_me"
                color="#464688"
                label="تذكرني"
                hide-details
              />
            </div>
            <v-btn
              :loading="authStore.loading || homeStore.loading"
              class="login-btn"
              height="56"
              :class="notActive ? 'not-active' : ''"
              @click="login"
            >
              <span> دخول </span>
            </v-btn>
            <v-btn
              v-if="notActive"
              :loading="authStore.loading"
              height="56"
              class="login-btn"
              @click="activateEmail"
            >
              <span>إعادة ارسال رسالة التفعيل</span>
            </v-btn>
            <div v-if="notActive" class="activate-section">
              <span class="unactive-account-hint">
                حسابك غير مفعل ، تم إرسال رابط التفعيل عبر البريد الإلكتروني ،
                يرجى التحقق واتباع التعليمات.
              </span>
              <span class="card-line" />
              <span class="subtitle-text">
                اذا لم تصلك رسالة التأكيد فضلاً التحقق من البريد الإلكتروني
                الغير هام / المهمل او يمكنك تغيير البريد الإلكتروني
              </span>
              <v-btn
                :loading="authStore.loading"
                class="login-btn"
                height="56"
                @click="openChangeEmailDialog"
              >
                <span>تغيير البريد الإلكتروني</span>
              </v-btn>
            </div>
          </v-form>
          <div class="other-way">
            <div>
              <h3>ليس لديك حساب؟</h3>
              <span @click="signup">حساب جديد</span>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <ForgetPassword
        :dialog-visible="showForgetDialog"
        @closeModal="isForgetDialogClosed"
      />
      <ActivationDialog
        :dialog-visible="showDialog"
        :dialog-data="dialogData"
        @closeModal="isDialogClosed"
      />
      <UpdatedTermsDialog
        :dialog-visible="showReadTermsDialog"
        @dialogClosed="onTermsDialogClosed"
        @acceptTerms="onAcceptTerms"
        :message="termsDialogContent"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";

import UpdatedTermsDialog from "./UpdatedTermsDialog.vue";
import { useNuxtApp } from "#app";
import ActivationDialog from "~/components/auth/ActivationDialog.vue";
import Alert from "~/components/shared/Alert.vue";
import ForgetPassword from "~/components/auth/ForgetPassword.vue";
import ChangeEmailForm from "~/components/auth/changeEmailForm.vue";
import { useAuthStore } from "~/stores/AuthStore";
import { useHomeStore } from "~/stores/HomeStore";
const homeStore = useHomeStore();

const authStore = useAuthStore();

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  openThisPath: {
    type: String,
    default: "",
  },
  setInCart: {
    type: Boolean,
    default: false,
  },
  isItFree: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "closeModal",
  "onSetInCart",
  "onSetInMySubscription",
]);

const router = useRouter();
const route = useRoute();

const { $fire, $config } = useNuxtApp();

const termsDialogContent = ref("");
const isError = ref(false);
const errorMessage = ref("");
const showLoginDialog = ref(false);
const showReadTermsDialog = ref(false);
const showTermsDialog = ref(false);
const showForgetDialog = ref(false);
const showChangeEmailForm = ref(false);
const showDialog = ref(false);
const dialogData = ref({});
const showAlert = ref(false);
const alertData = ref({});
const showSignup = ref(false);
const showReadPolicyCheckBox = ref(false);
const hasReadPolicy = ref(false);
const loading = ref(false);
const formRef = ref({});

const form = reactive({
  email: "",
  password: "",
  remember_me: true,
  device_id: "",
  fcm_token: "",
});

const notActive = ref(false);

const emailRules = [
  (v) => !!v || "البريد الإلكتروني مطلوب",
  (v) =>
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v,
    ) || "البريد الإلكتروني غير صحيح",
];

const passwordRules = [
  (v) => !!v || "كلمة المرور مطلوبة",
  (v) => v.length >= 2 || "طول كلمة المرور يجب أن لا يقل عن 2 أحرف",
];

const isIOSDevice = ref(false);

if (process.client) {
  onMounted(() => {
    isIOSDevice.value =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  });
}

watch(
  () => props.dialogVisible,
  (val) => {
    showLoginDialog.value = val;
    if (val === true) {
      showSignup.value = false;
      setNotificationToken();
      setDeviceId();
    }
  },
);

watch(showLoginDialog, (val) => {
  if (!val) {
    isError.value = false;
    formRef.value.resetValidation();
    emit("closeModal", { value: true, showSignup: showSignup.value });
    if (props.setInCart) {
      emit("onSetInCart", true);
    }
    if (props.isItFree) {
      emit("onSetInMySubscription", true);
    }
    resetForm();
  }
});

const resetForm = () => {
  form.email = "";
  form.password = "";
  form.remember_me = true;
  form.device_id = "";
  form.fcm_token = "";
  loading.value = false;
  notActive.value = false;
};

const activateEmail = async () => {
  const valid = await formRef.value.validate();

  if (valid) {
    const formData = new FormData();
    formData.set("email", form.email);
    const resData = await authStore.resendActivationEmail(formData);
    if (resData.success) {
      dialogData.value = {
        type: "",
        msg: resData.message,
      };
      showLoginDialog.value = false;
      showDialog.value = true;
      notActive.value = false;
    } else {
      setAlertData(resData);
    }
  }
};

const openChangeEmailDialog = () => {
  showChangeEmailForm.value = true;
};

const onShowAlert = (data) => {
  setAlertData(data);
  if (data.success) {
    showChangeEmailForm.value = false;
    notActive.value = false;
    resetForm();
  }
};

const checkEmail = (value) => {
  if (value.includes("@")) {
    return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    );
  } else {
    return value;
  }
};

const isForgetDialogClosed = (payload) => {
  if (payload.value) {
    showForgetDialog.value = false;
  }
};

const isSupported = () => {
  return (
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window
  );
};

const isAlertClosed = (payload) => {
  if (payload.value) {
    showAlert.value = false;
  }
};

const isDialogClosed = (payload) => {
  if (payload.value) {
    showDialog.value = false;
  }
};

const forgetPassword = () => {
  showLoginDialog.value = false;
  showForgetDialog.value = true;
};

const signup = () => {
  showLoginDialog.value = false;
  showSignup.value = true;
};

const login = async () => {
  isError.value = false;
  const status = await formRef.value.validate();

  if (status.valid) {
    if (showReadPolicyCheckBox.value && hasReadPolicy.value) {
      form.has_read_policy = hasReadPolicy.value;
    }

    loading.value = true;

    await authStore.login(form).then(async (res) => {
      setAlertData({ success: res.success, message: res.message });
      if (res.status_code === 200) {
        showLoginDialog.value = false;
      }
      await homeStore.fetchHome();
      loading.value = false;
      if (res.status_code === 200) {
        showLoginDialog.value = false;
        if (props.openThisPath) {
          navigateTo(props.openThisPath);
        }
      } else {
        handleLoginError(res);
      }
    });
    loading.value = false;
  }
};

const handleLoginError = (e) => {
  if (e.status_code === 310) {
    notActive.value = true;
  } else if (e.status_code === 406) {
    termsDialogContent.value = e.data;
    showReadTermsDialog.value = true;
    showReadPolicyCheckBox.value = true;
  }
  if (e.status_code !== 310) {
    isError.value = true;
    errorMessage.value = e ? e.message : "حدث خطأ ما ، الرجاء إعادة المحاولة";
  }
};

const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

const setNotificationToken = () => {
  // const { messaging } = $fire;
  if (!isSupported() || isIOSDevice.value) {
    return false;
  } else if (Notification) {
    // Notification.requestPermission((permissions) => {
    //   if (permissions === "granted") {
    //     messaging
    //       .getToken({
    //         vapidKey: process.env.fcmPublicVapIdKeyEnv || $config.fcmPublicVapIdKeyConfig
    //       })
    //       .then((currentToken) => {
    //         form.fcm_token = currentToken;
    //       });
    //   }
    // });
  }
};

const setDeviceId = () => {
  if (localStorage.getItem("deviceId") === null) {
    const generateRandomId = Math.floor(Math.random() * 899999 + 100000);
    localStorage.setItem("deviceId", generateRandomId);
  }
  form.device_id = localStorage.getItem("deviceId");
};

const closeChangeEmailDialog = () => {
  emit("closeModal", { value: true, showSignup: showSignup.value });
  showLoginDialog.value = false;
  setTimeout(() => {
    showChangeEmailForm.value = false;
    notActive.value = false;
  }, 400);
};

const onAcceptTerms = () => {
  hasReadPolicy.value = true;
  showReadTermsDialog.value = false;
};

const onTermsDialogClosed = (status) => {
  showReadTermsDialog.value = false;
  if (status) {
    showLoginDialog.value = false;
  }
};
</script>

<style lang="scss">
@import "./assets/scss/_login.scss";
</style>
